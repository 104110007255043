/* global Handlebars, bindFKDCalculator, dumpBDJSON, dumpAllBDJSON */
(()=>{

    /**
     * Render HBS template to a wrapper by ID
     * @param {JSON} data JSON data
     * @param {String} templateId wrapper to get the template from ID
     * @param {String} targetId wrapper to put the conmpiled markup to ID
     */
    const renderHBTemplate = (data, templateId, targetId) => {
        let templateMarkup = document.getElementById(templateId).innerHTML;
        let template = Handlebars.compile(templateMarkup);
        let target = document.getElementById(targetId);

        target.innerHTML =  template(data);
    };
    window.renderHBTemplate = renderHBTemplate;

    /**
     * Get bullet drop tables array
     * @param {String} filterName filter bullet drop tables based on given name
     * @returns {array} array of bullet drop tables
     */
    const getBDTables = (filterName) => {
        if (!filterName) {
            return localStorage.getItem('bd') ? JSON.parse(localStorage.getItem('bd')) : [];
        } else {
            let tables = localStorage.getItem('bd') ? JSON.parse(localStorage.getItem('bd')) : [];
            
            return tables.map((table)=>{
                if (!table.name.toLowerCase().indexOf(filterName.toLowerCase())){
                    table.hidden = false;
                    return table;
                } else {
                    table.hidden = true;
                    return table;
                }
            });
        }
    }
    window.getBDTables = getBDTables;

    /**
     * Get bullet drop table by index
     * @param {Number} index index of bullet drop data to return
     * @returns {Object} bullet drop table data
     */
    const getBDTable = (index) => {
        return JSON.parse(localStorage.getItem('bd'))[index] ? JSON.parse(localStorage.getItem('bd'))[index] : null;
    };
    window.getBDTable = getBDTable;

    /**
     * Set bullet drop table by index
     * @param {Number} index index of bullet drop data to set
     * @param {JSON} BDTable JSON to set on given index
     */
    const setBDTable = (index, BDTable) => {
        let BDTables = getBDTables();

        if (BDTables && index && BDTable) {
            BDTables[index] = BDTable;
            localStorage.setItem('bd', JSON.stringify(BDTables));
        }
    };

    /**
     * Check does a given bullet drop table name exists in array
     * @param {String} tableName name of the bullet drop table you are looking for
     * @param {Array} tableArray array of bullet drop table objects
     * @returns {Boolean} does the tableName exist in tableArray
     */
    const checkDoesTableExist = (tableName, tableArray) => {
        let exists = false;
        tableArray.forEach((item)=>{
            if (item.name === tableName) {
                exists = true;
            }
        });
        return exists;
    }

    /**
     * Print bullet drop tables list
     * @param {String} filterName filter bullet drop tables based on given name
     */
    const printBDTables = (filterName) => {
        let BDTablesArray = getBDTables(filterName);

        renderHBTemplate(BDTablesArray, 'bullet-drop__list--template', 'bullet-drop__list-target');
        document.querySelector('.bullet-drop__back').classList.add('bullet-drop__back--hidden');
    };
    window.printBDTables = printBDTables;

    /**
     * Go back to bullet drop tables list
     */
    const goBackToBTList = (e) => {
        e.preventDefault();
        document.querySelector('.bullet-drop').setAttribute('data-show','list');
        document.querySelector('.bullet-drop__back').classList.add('bullet-drop__back--hidden');
    }

    /**
     * Rename bullet drop table
     * @param {String} name new bullet drop table name
     * @param {Number} tableIndex bullet drop table index
     */
    const renameBDTable = (tableName, tableIndex) => {
        let BDTablesArray = getBDTables();

        // Change the name
        BDTablesArray[tableIndex].name = tableName;

        // Save BDTables
        localStorage.setItem('bd', JSON.stringify(BDTablesArray));

        // Re-render export textarea
        dumpBDJSON(tableIndex);
    };

    /**
     * Save bullet drop table description
     * @param {String} desc bullet drop table description
     * @param {Number} tableIndex bullet drop table index
     */
    const saveBDTableDesc = (desc, tableIndex) => {
        let BDTablesArray = getBDTables();

        // Change the name
        BDTablesArray[tableIndex].desc = desc;

        // Save BDTables
        localStorage.setItem('bd', JSON.stringify(BDTablesArray));

        // Re-render export textarea
        dumpBDJSON(tableIndex);
    };

    /**
     * Clear add bullet drop table input
     */
    const clearAddInput = () => {
        document.getElementById('bullet-drop__name').value = '';
    }

    /**
     * Add new bullet drop table
     * @param {String} tableName name of the new bullet drop table
     */
    const addBDTable = (tableName) => {
        let BDTablesArray = getBDTables();

        // Clear status wrapper
        document.getElementById('bullet-drop__list-status-wrapper').innerHTML = '';

        // Check does a table with given name already exists
        if (checkDoesTableExist(tableName, BDTablesArray)) {
            renderHBTemplate({"status": "Podana nazwa już istnieje!"}, 'warning-template', 'bullet-drop__list-status-wrapper');
            return false;
        
        // Create new table with given name
        } else {
            let tableObject = {
                "name": tableName,
                "table": []
            }
            BDTablesArray.push(tableObject);

            // Write 
            localStorage.setItem('bd', JSON.stringify(BDTablesArray));

            // Refresh
            printBDTables();

            // Display info
            if (checkDoesTableExist(tableName, getBDTables())) {
                renderHBTemplate({"status": `Dodano "${tableName}"`}, 'success-template', 'bullet-drop__list-status-wrapper');
                clearAddInput();
            } else {
                renderHBTemplate({"status": `Błąd w trakcie dodawania "${tableName}"`}, 'warning-template', 'bullet-drop__list-status-wrapper');
            }

            // Refresh Export JSON
            dumpAllBDJSON();

        }
    };

    /**
     * Remove bullet drop table
     * @param {Number} index
     * @param {String} name
     */
    const removeBDTable = (index, name) => {
        let BDTablesArray = getBDTables();

        // Approve
        if (confirm(`Usunąć "${name}"`)) {
            
            // Remove
            BDTablesArray.splice(index,1);

            // Write 
            localStorage.setItem('bd', JSON.stringify(BDTablesArray));

            // Refresh
            printBDTables(document.getElementById('bullet-drop__name').value);

            // Refresh Export JSON
            dumpAllBDJSON();

        }
    };

    /**
     * Print bullet drop table
     * @param {Number} index index of bullet drop table to print
     */
    const printBDTable = (index) => {
        let BDTable = getBDTable(index);

        if (BDTable) {

            // Show table wrapper, hide table list
            document.querySelector('.bullet-drop').setAttribute('data-show', 'table');
            
            // Render table
            BDTable.index = index;
            renderHBTemplate(BDTable, 'bullet-drop__table--template', 'bullet-drop__table-target');

            // Bind calculator
            bindFKDCalculator();

            // Calculate table clicks
            calculateClicksInTable();

            // Dump export JSON
            dumpBDJSON(index);

            // Show back button
            document.querySelector('.bullet-drop__back').classList.remove('bullet-drop__back--hidden');

            // Bind add/remove row 
            [...document.querySelectorAll('.bullet-drop__table-add')].forEach((button)=>{
                button.addEventListener('click',(e)=>{
                    addBDRow(e.target.getAttribute('data-index'),e.target.getAttribute('data-tableindex'));
                });
            });
            [...document.querySelectorAll('.bullet-drop__table-remove')].forEach((button)=>{
                button.addEventListener('click',(e)=>{
                    removeBDRow(e.target.getAttribute('data-index'),e.target.getAttribute('data-tableindex'));
                });
            });

        }
    };

    /**
     * Calculate clicks in mrad
     * @param {Number} d dystans
     * @param {Number} o opad
     * @returns {Number} ammount of clicks
     */
    const calculateMradDrop = (d,o) => {
        return Math.round((o/d)*10);
    };

    /**
     * Calculate clicks in custom type
     * @param {Number} d dystans
     * @param {Number} o opad
     * @param {Number} customType custom click value in mm/100m
     * @returns {Number} ammount of clicks
     */
    const calculateCustomDrop = (d,o,customType) => {
        return Math.round(o/(customType*(d/100)));
    };

    /**
     * Calculate clicks in entire bullet drop table
     */
    const calculateClicksInTable = () => {
        let tableRows = [...document.querySelectorAll('.bullet-drop__table-row.bullet-drop__table-row--calc')];

        tableRows.forEach((row)=>{
            let d = parseInt(row.querySelector('.bullet-drop__table-row--distance').innerHTML, 10);
            let o = parseInt(row.querySelector('.bullet-drop__table-row--drop').innerHTML, 10);
            let mradWrapper = row.querySelector('.bullet-drop__table-row--mrad');
            let inchWrapper = row.querySelector('.bullet-drop__table-row--inch');

            // Write mrad clicks
            mradWrapper.innerHTML = calculateMradDrop(d,o);

            // Write custom clicks
            let customType = parseFloat(document.querySelector('.bullet-drop__table-clicktype').value,10);
            inchWrapper.innerHTML = calculateCustomDrop(d,o,customType);
        });

    };

    /**
     * Calculate clicks while adding row
     * @param {String} target event target used to identify upper or lower adding row
     */
    const calculateNewRow = (target) => {
        let indexId = target.getAttribute('data-index');
        let d = document.querySelector('.bullet-drop__table-input-d[data-index="'+indexId+'"]').value;
        let o = document.querySelector('.bullet-drop__table-input-o[data-index="'+indexId+'"]').value;
        let mradWrapper = document.querySelector('.bullet-drop__table-row[data-index="'+indexId+'"] .bullet-drop__table-row--mrad');
        let inchWrapper = document.querySelector('.bullet-drop__table-row[data-index="'+indexId+'"] .bullet-drop__table-row--inch');
        let customType = parseFloat(document.querySelector('.bullet-drop__table-clicktype').value,10);

        if (d && o) {
            mradWrapper.innerHTML = calculateMradDrop(d,o);
            inchWrapper.innerHTML = calculateCustomDrop(d,o,customType);
        } else {
            mradWrapper.innerHTML = '';
            inchWrapper.innerHTML = '';
        }
    };

    /**
     * Add new row to bullet drop table
     * @param {String} indexId upper or lower add
     * @param {Number} tableIndex bullet drop table index
     */
    const addBDRow = (indexId, tableIndex) => {
        let d = parseInt(document.querySelector('.bullet-drop__table-input-d[data-index="'+indexId+'"]').value, 10);
        let o = parseInt(document.querySelector('.bullet-drop__table-input-o[data-index="'+indexId+'"]').value, 10);

        if (d && o && tableIndex) {
            let BDTable = getBDTable(tableIndex);
            let newRow = {"d":d,"o":o};

            // Push new row
            BDTable.table.push(newRow);

            // Sort by distance
            BDTable.table = BDTable.table.sort((a,b)=>(a.d > b.d) ? 1 : ((b.d > a.d) ? -1 : 0));

            // Save
            setBDTable(tableIndex, BDTable);
            
            // Refresh table
            printBDTable(tableIndex);

        }
    };

    /**
     * Remove row from bullet drop table
     * @param {Number} indexId row index
     * @param {Number} tableIndex bullet drop table index 
     */
    const removeBDRow = (indexId, tableIndex) => {
        if (indexId && tableIndex) {
            let d = document.querySelector('.bullet-drop__table-row[data-index="'+indexId+'"] .bullet-drop__table-row--distance').innerHTML;
            let o = document.querySelector('.bullet-drop__table-row[data-index="'+indexId+'"] .bullet-drop__table-row--drop').innerHTML; 

            if (confirm(`Usunąć ${d}[m] / ${o}[mm] ?`)) {
                let BDTable = getBDTable(tableIndex);

                // Remove row
                BDTable.table.splice(indexId,1);

                // Save
                setBDTable(tableIndex, BDTable);

                // Refresh table
                printBDTable(tableIndex);
            }
        }
    };

    // Init
    if (document.querySelector('.bullet-drop')) {

        // Print stored bullet drop tables list 
        printBDTables();

        // Bind add button
        document.querySelector('.bullet-drop__add-table').addEventListener('click',(e) => {
            e.preventDefault();
            let tableName = document.querySelector('.bullet-drop__form-input').value;

            if (!tableName.replace(/\s/g,'').length) {
                renderHBTemplate({"status": "Podaj poprawną nazwę!"}, 'warning-template', 'bullet-drop__list-status-wrapper');
            } else {
                addBDTable(tableName);
            }
        });

        // Go back to bullet drop tables list 
        document.querySelector('.bullet-drop__back').addEventListener('click',(e) => {
            goBackToBTList(e);
        });

        // Click event
        document.addEventListener('click', (e)=>{
                        
            // Input new value
            if (e.target.classList.contains('bullet-drop__table-input')) {
                calculateNewRow(e.target);
            
            // Bind remove button
            } else if (e.target.classList.contains('bullet-drop__list-item-remove')) {
                e.preventDefault();
                let index = parseInt(e.target.getAttribute('data-index'), 10);
                let name = e.target.getAttribute('data-name');

                removeBDTable(index, name);
            
            // Show bullet drop table
            } else if (e.target.classList.contains('bullet-drop__list-item')) {
                e.preventDefault();
                let index = parseInt(e.target.getAttribute('data-index'), 10);

                printBDTable(index);
            }
            
        });

        // Keyup event
        document.addEventListener('keyup', (e)=>{
            
            // Rename bullet drop table name
            if (e.target.classList.contains('bullet-drop__table-name')) {
                e.preventDefault();
                renameBDTable(e.target.value, e.target.getAttribute('data-tableindex'));
            
            // Save bullet drop table description
            } else if (e.target.classList.contains('bullet-drop__table-desc')) {
                e.preventDefault();
                saveBDTableDesc(e.target.value, e.target.getAttribute('data-tableindex'));
            }

        });

        // Filter bullet drop table list
        document.getElementById('bullet-drop__name').addEventListener('change', (e)=>{printBDTables(e.target.value)});
        document.getElementById('bullet-drop__name').addEventListener('keyup', (e)=>{printBDTables(e.target.value)});

        // Calculate in new row
        document.addEventListener('change', (e)=>{
            
            // Input new value
            if (e.target.classList.contains('bullet-drop__table-input')) {
                calculateNewRow(e.target);

            // Units change
            } else if (e.target.classList.contains('bullet-drop__table-clicktype')) {
                
                // Recalculate table
                calculateClicksInTable();
                
                // Recalculate new rows
                [...document.querySelectorAll('.bullet-drop__table-input')].forEach((input)=>{
                    if(input.value.length !== 0) {
                        calculateNewRow(input);
                    }
                });

            }
        });
    }

})();

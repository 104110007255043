(() => {

    const calculateFKD = (id) => {
        let front = document.getElementById('fkd_f').value;
        let frontDisabled = document.getElementById('fkd_f').disabled;
        let kat = document.getElementById('fkd_k').value;
        let katDisabled = document.getElementById('fkd_k').disabled;
        let dystans = document.getElementById('fkd_d').value;
        let dystansDisabled = document.getElementById('fkd_d').disabled;

        if (frontDisabled || katDisabled || dystansDisabled) {

            // Dystans
            if (!frontDisabled && !katDisabled && dystansDisabled) {
                document.getElementById('fkd_d').disabled = true;
                document.getElementById('fkd_d').value = Math.round(front/kat);

            // Kąt
            } else if (!frontDisabled && katDisabled && !dystansDisabled) {
                document.getElementById('fkd_k').disabled = true;
                //document.getElementById('fkd_k').value = Math.round(front/dystans);
                document.getElementById('fkd_k').value = Math.round((front/dystans)*10)/10;

            // Front
            } else if (frontDisabled && !katDisabled && !dystansDisabled) {
                document.getElementById('fkd_f').disabled = true;
                document.getElementById('fkd_f').value = Math.round(kat*dystans);
            }

        } else {

            // Dystans
            if (front && kat && id !== 'fkd_d') {
                document.getElementById('fkd_d').disabled = true;
                document.getElementById('fkd_d').value = Math.round(front/kat);
            
            // Kąt
            } else if (front && dystans && id !== 'fkd_k') {
                document.getElementById('fkd_k').disabled = true;
                document.getElementById('fkd_k').value = Math.round(front/dystans);
            
            // Front
            } else if (kat && dystans && id !== 'fkd_f') {
                document.getElementById('fkd_f').disabled = true;
                document.getElementById('fkd_f').value = Math.round(kat*dystans);
            }
        }

    };
    window.calculateFKD = calculateFKD;

    // Reset
    const resetFKD = () => {
        document.getElementById('fkd_f').disabled = false;
        document.getElementById('fkd_k').disabled = false;
        document.getElementById('fkd_d').disabled = false;
    };
    window.resetFKD = resetFKD;

    // Bind
    const bindFKDCalculator = () => {
        // Bind - reset
        document.querySelector('.fkd__reset').addEventListener('click', ()=>{
            document.getElementById('fkd_f').value = '';
            document.getElementById('fkd_k').value = '';
            document.getElementById('fkd_d').value = '';
            resetFKD();
        });

        // Bind - inputs
        document.getElementById('fkd_f').addEventListener('change', (e) => { calculateFKD(e.target.id); });
        document.getElementById('fkd_f').addEventListener('keyup', (e) => { calculateFKD(e.target.id); });
        document.getElementById('fkd_k').addEventListener('change', (e) => { calculateFKD(e.target.id); });
        document.getElementById('fkd_k').addEventListener('keyup', (e) => { calculateFKD(e.target.id); });
        document.getElementById('fkd_d').addEventListener('change', (e) => { calculateFKD(e.target.id); });
        document.getElementById('fkd_d').addEventListener('keyup', (e) => { calculateFKD(e.target.id); });
    };
    window.bindFKDCalculator = bindFKDCalculator;

    // Bind buttons and inputs
    if (document.querySelector('.fkd__reset')) {
        bindFKDCalculator();
    }

})();


if (document.querySelector('body.cpf__body')) {

    const fileInput = document.getElementById('cpfFile');
    const canvas = document.getElementById('cpfField');

    fileInput.addEventListener('change', (e)=>{
        const reader = new FileReader();
        reader.onload = () =>{
            console.log(reader.result);
            const base64Img = reader.result;
            canvas.style.backgroundImage = `url('${base64Img}')`; 


        }
        reader.readAsDataURL(e.target.files[0]);
    });

    const zoomInBtn = document.getElementById('cpfZoomIn');
    const zoomOutBtn = document.getElementById('cpfZoomOut');

    const zoomImg = (type) => {
        const bgSize = parseInt(canvas.style.backgroundSize, 10);

        if (type === 'in') {
            canvas.style.backgroundSize = `${bgSize+20}%`;
        } else {
            canvas.style.backgroundSize = `${bgSize-20}%`;
        }
    };

    zoomInBtn.addEventListener('click', (e)=>{
        e.preventDefault();
        zoomImg('in');
    });
    zoomOutBtn.addEventListener('click', (e)=>{
        e.preventDefault();
        zoomImg('out');
    });

    const moveUp = document.getElementById('cpfMoveUp');
    const moveRight = document.getElementById('cpfMoveRight');
    const moveDown = document.getElementById('cpfMoveDown');
    const moveLeft = document.getElementById('cpfMoveLeft');

    const moveImg = (direction) => {
        const xPosition = parseInt(canvas.style.backgroundPositionX, 10);
        const yPosition = parseInt(canvas.style.backgroundPositionY, 10);
        
        if (direction === 'up') {
            canvas.style.backgroundPosition = `${xPosition}% ${yPosition+10}%`;
        }
        if (direction === 'right') {
            canvas.style.backgroundPosition = `${xPosition+10}% ${yPosition}%`;
        }
        if (direction === 'down') {
            canvas.style.backgroundPosition = `${xPosition}% ${yPosition-10}%`;
        }
        if (direction === 'left') {
            canvas.style.backgroundPosition = `${xPosition-10}% ${yPosition}%`;
        }
    };

    moveUp.addEventListener('click', (e)=>{
        e.preventDefault();
        moveImg('up');
    });
    moveRight.addEventListener('click', (e)=>{
        e.preventDefault();
        moveImg('right');
    });
    moveDown.addEventListener('click', (e)=>{
        e.preventDefault();
        moveImg('down');
    });
    moveLeft.addEventListener('click', (e)=>{
        e.preventDefault();
        moveImg('left');
    });

    const addPoint = document.getElementById('cpfAddPoint');
    const pointCount = document.getElementById('cpfPointCount');

    const calculateCPF = () => {
        const points = document.querySelectorAll('.cpf__point');
        const pointsX = [];
        const pointsY = [];

        [...points].forEach((point)=>{
            pointsX.push(parseInt(point.style.left, 10));
            pointsY.push(parseInt(point.style.top, 10));
        });

        const avgX = parseInt((pointsX.reduce((a, b) => a + b, 0))/pointsX.length, 10);
        const avgY = parseInt((pointsY.reduce((a, b) => a + b, 0))/pointsY.length, 10);

        pointCount.innerHTML = [...points].length;

        if (document.querySelectorAll('.cpf__point').length >= 2) {
            if (!document.querySelector('.cpf__center')) {
                const point = document.createElement('div');
                point.classList.add('cpf__center');
                canvas.appendChild(point);
            }

            document.querySelector('.cpf__center').style.left = `${avgX}px`;
            document.querySelector('.cpf__center').style.top = `${avgY}px`;
        }

    };

    const addNewPoint = () => {
        const point = document.createElement('div');
        point.classList.add('cpf__point');
        
        const pointDrag = document.createElement('span');
        pointDrag.classList.add('cpf__drag');
        point.appendChild(pointDrag);
        
        canvas.appendChild(point);

        point.addEventListener('touchmove', (e)=>{
            let touchLocation = e.targetTouches[0];
            point.style.left = touchLocation.pageX + 'px';
            point.style.top = touchLocation.pageY + 'px';
        });

        point.addEventListener('touchend', (e)=>{
            e.preventDefault();
            calculateCPF();
        });

    };

    addPoint.addEventListener('click', (e)=>{
        e.preventDefault();
        addNewPoint('left');
    });

}

(() => {

    /**
     * Convert units
     * @param {String} inType in type
     * @param {Number} inValue in value
     * @param {String} outType out type
     * @returns {Number} returns a value specified by outType
     */
    const convertUnits = (inType, inValue, outType) => {
        
        let distanceTable = {
            "units": [
                "mm", "cm", "inch", "ft", "m", "yd"
            ],
            "proportions": {
                "mm":   1,
                "cm":   0.1,
                "inch": 0.03937,
                "ft":   0.00328,
                "m":    0.001,
                "yd":   0.0010936
            }
        };

        let speedTable = {
            "units": [
                "ms", "fps", "kmh"
            ],
            "proportions": {
                "ms":  1,
                "fps": 3.28084,
                "kmh": 3.6
            }
        };

        let presureTable = {
            "units": [
                "mpa", "bar", "psi", "mmhg", "atm", "hpa"
            ],
            "proportions": {
                "mpa":  1,
                "bar":  10,
                "psi":  145.038,
                "mmhg": 7500,
                "atm":  9.87,
                "hpa":  10000
            }
        };

        let massTable = {
            "units": [
                "g", "gr", "oz", "lb", "kg"
            ],
            "proportions": {
                "g":  1,
                "gr": 15.43236,
                "oz": 0.03527,
                "lb": 0.0022,
                "kg": 0.001
            }
        };

        let angleUnits = {
            "units": [
                "mrad", "moa", "mil"
            ],
            "proportions": {
                "mrad": 1,
                "moa":  3.43775,
                "mil":  1.0185916
            }
        };

        // Get proportions from proper table
        let calculateTable = [
            distanceTable,
            speedTable,
            presureTable,
            massTable,
            angleUnits
        ].filter((table)=>{
            return table.units.indexOf(inType) === -1 ? false : true;
        })[0]['proportions'];

        // Return rounded result
        return ((calculateTable[outType] * inValue) / calculateTable[inType]).toPrecision(4);
    };
    
    /**
     * Do the calculation and write output value
     * @param {String} inType input type 
     * @param {Number} inValue input value
     * @param {String} outType output type
     * @param {Node} outNode  output node
     */
    const calculateValue = (inType, inValue, outType, outNode) => {
        outNode.value = convertUnits(inType, inValue, outType);
    };

    /**
     * Reset calculator
     * @param {Node} inNode input node
     * @param {Node} outNode  output node
     */
    const resetCalc = (inNode, outNode) => {
        inNode.value = '';
        outNode.value = '';
    };

    /**
     * Run calculator
     * @param {Node} input_select input select node
     * @param {Node} input_value  input select value
     * @param {Node} output_select output select node
     * @param {Node} output_value  output select value
     * @param {Node} reset reset button
     */
    const bindCalculator = (input_select, input_value, output_select, output_value, reset) => {

        // Input select change
        input_select.addEventListener('change', (e)=>{
            let inType = e.target.value;
            let inValue = input_value.value || 0;
            let outType = output_select.value;
            
            calculateValue(inType, inValue, outType, output_value);
        });

        // Output select change
        output_select.addEventListener('change', (e)=>{
            let inType = input_select.value;
            let inValue = input_value.value || 0;
            let outType = e.target.value;
            
            calculateValue(inType, inValue, outType, output_value);
        });

        // Input value change
        input_value.addEventListener('change', (e)=>{
            let inType = input_select.value;
            let inValue = e.target.value || 0;
            let outType = output_select.value;
            
            calculateValue(inType, inValue, outType, output_value);
        });

        // Input value change
        input_value.addEventListener('keyup', (e)=>{
            let inType = input_select.value;
            let inValue = e.target.value || 0;
            let outType = output_select.value;
            
            calculateValue(inType, inValue, outType, output_value);
        });

        // Reset
        reset.addEventListener('click', ()=>{
            resetCalc(input_value, output_value);
        });

    };


    // Init
    if (document.querySelector('.unit-conversion__calc')) {
        
        // Run each calculator
        [...document.querySelectorAll('.unit-conversion__calc')].forEach((calc)=>{
            let input_select = document.getElementById(calc.dataset['input_select']);
            let input_value = document.getElementById(calc.dataset['input_value']);
            let output_select = document.getElementById(calc.dataset['output_select']);
            let output_value = document.getElementById(calc.dataset['output_value']);
            let reset = document.getElementById(calc.dataset['reset']);

            bindCalculator(input_select, input_value, output_select, output_value, reset);
        });
    }


})();


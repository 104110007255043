/* global getBDTable, renderHBTemplate, getBDTables, printBDTables */

/**
 * Write bullet drop table to textarea for export
 * @param {Number} index index of bullet drop table to dump
 */
const dumpBDJSON = (index) => {
    let BDTable = getBDTable(index);
    let targetTextarea = document.querySelector('.bullet-drop__export-json');

    targetTextarea.innerHTML = JSON.stringify(BDTable);
};
window.dumpBDJSON = dumpBDJSON;

/**
 * Write all bullet drop tables to textarea for export
 */
const dumpAllBDJSON = () => {
    let BDTables = localStorage.getItem('bd') || '[]';
    let targetTextarea = document.querySelector('.bullet-drop__export-all-json');

    targetTextarea.innerHTML = BDTables;
};
window.dumpAllBDJSON = dumpAllBDJSON;

/**
 * Copy BDJSON to clipboard
 * @param {Node} targetTextarea textarea node to copy from
 * @param {String} statusWrapperId id of the status wrapper to print status to
 */
const copyBDJSONToClipboard = (targetTextarea, statusWrapperId) => {
    try {
        let copyBDJSON = targetTextarea;

        // Select the text field
        copyBDJSON.select();
        copyBDJSON.setSelectionRange(0, 99999); /*For mobile devices*/

        // Copy the text inside the text field
        document.execCommand("copy");

        // Write success status
        renderHBTemplate({"status": "Skopiowano do schowka"}, 'success-template', statusWrapperId);

    } catch(err) {
        
        // Display error
        renderHBTemplate({"status": "Błąd kopiowania - skopiuj ręcznie"}, 'warning-template', statusWrapperId);
    }
};

/**
 * Trigger Import button :disabled 
 * @param {Node} targetTextarea import textarea
 */
const triggerImportButtonStatus = (targetTextarea) => {
    try {
        let BDTable = JSON.parse(targetTextarea.value);

        // Test is it a single table
        if (BDTable.hasOwnProperty('name') && BDTable.hasOwnProperty('table')) {

            // Enable import button
            document.querySelector('.bullet-drop__import-button').disabled = false;

            // Hide error
            document.getElementById('bullet-drop__import-status-wrapper').innerHTML = '';
        
        // Test is it a full export
        } else if (Array.isArray(BDTable)) {
            var isProperArray = true;

            BDTable.forEach((BDTable)=>{
                console.log(!BDTable.hasOwnProperty('name') || !BDTable.hasOwnProperty('table'));
                if (!BDTable.hasOwnProperty('name') || !BDTable.hasOwnProperty('table')) {
                    isProperArray = false;
                }
            });

            if (isProperArray) {
                
                // Enable import button
                document.querySelector('.bullet-drop__import-button').disabled = false;

                // Hide error
                document.getElementById('bullet-drop__import-status-wrapper').innerHTML = '';
            
            } else {
                
                // Disable import button
                document.querySelector('.bullet-drop__import-button').disabled = true;

            }
        } else {
                
            // Disable import button
            document.querySelector('.bullet-drop__import-button').disabled = true;
            
        }

    } catch(err) {

        // Display error
        renderHBTemplate({"status": "Błędny format danych"}, 'warning-template', 'bullet-drop__import-status-wrapper');

        // Disable import button
        document.querySelector('.bullet-drop__import-button').disabled = true;
    }
    
};

/**
 * Import bullet drop from textarea
 * @param {JSON} importJSON json to import
 */
const importBDJSON = (importJSON) => {
    let BDTablesArray = getBDTables();

    // Check is single or full import 
    if (Array.isArray(importJSON)) {

        importJSON.forEach((BDTable)=>{
            importBDJSON(BDTable);
        });

    // Single import
    } else {

        // Prevent duplicate names
        BDTablesArray.forEach((table)=>{
            if (table.name === importJSON.name) {
                importJSON.name = `${importJSON.name} #${Date.now().toString()}`;
            }
        });

        // Add new table
        BDTablesArray.push(importJSON);

        // Save BDTables
        localStorage.setItem('bd', JSON.stringify(BDTablesArray));

        // Re-render
        printBDTables();

        // Clear textarea
        document.querySelector('.bullet-drop__import-json').value = '';
        document.querySelector('.bullet-drop__import-button').disabled = true;

        // Refresh Export JSON
        dumpAllBDJSON();

    }
    
};

// Init
if (document.querySelector('.bullet-drop')) {

    // Copy BDJSON to clipboard
    document.querySelector('.bullet-drop__export-button').addEventListener('click',(e)=>{
        e.preventDefault();
        copyBDJSONToClipboard(document.querySelector('.bullet-drop__export-json'), 'bullet-drop__export-status-wrapper');
    });
    document.querySelector('.bullet-drop__export-all-button').addEventListener('click',(e)=>{
        e.preventDefault();
        copyBDJSONToClipboard(document.querySelector('.bullet-drop__export-all-json'), 'bullet-drop__export-all-status-wrapper');
    });

    // Trigger Import button :disabled 
    document.querySelector('.bullet-drop__import-json').addEventListener('change',(e)=>{ triggerImportButtonStatus(e.target); });
    document.querySelector('.bullet-drop__import-json').addEventListener('keyup',(e)=>{ triggerImportButtonStatus(e.target); });

    // Import JSON
    document.querySelector('.bullet-drop__import-button').addEventListener('click', (e)=>{
        e.preventDefault();
        let importJSON = JSON.parse(document.querySelector('.bullet-drop__import-json').value);

        importBDJSON(importJSON);
    });

    // Write all bullet drop tables to textarea for export
    dumpAllBDJSON();

}
